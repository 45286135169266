import API from 'api';

import VideoForm from 'pages/videos/video.form';
import VideosPage from 'pages/videos/videos.page';

const VideosController = {
  basePath: '/videos',
  form: VideoForm,
  page: VideosPage,
  resourceAPI: API.Video,
};

export default VideosController;
