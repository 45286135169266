import MockedResource from 'api/mocked-resource';

export default class Company extends MockedResource {
  constructor(rootKey, uri) {
    super(rootKey, uri);

    this.list = [
      {
        id: 1,
        image: 'pepsico.jpg',
        name: 'Pepsico',
        address:
          'R. Verbo Divino, 1661 - Chácara Santo Antônio, São Paulo - SP, 04719-002',
        contact_information: '(11) 3905-5000',
        website: 'http://pepsico.com.br',
        make_test_in_animals: 1,
        sponsor_animal_cruelty: 0,
        created_at: '2022-09-05 18:48:01.723',
        updated_at: '2022-09-05 18:48:01.723',
        slug: 'pepsico',
        description:
          'PepsiCo, Inc. é uma empresa transnacional estadunidense de alimentos, lanches e bebidas com sede em Purchase, Nova York. A PepsiCo tem interesse na fabricação, comercialização e distribuição de lanches à base de grãos, bebidas e outros produtos.',
      },
      {
        id: 2,
        image: 'lola.jpg',
        name: 'Lola Cosmetics',
        address: 'R. Olimpíadas, 360 - Vila Olímpia, São Paulo - SP, 05502-001',
        contact_information: '(21)2323-0462',
        website: 'http://lolacosmetics.br',
        make_test_in_animals: 0,
        sponsor_animal_cruelty: 0,
        created_at: '2022-09-05 18:48:01.756',
        updated_at: '2022-09-05 18:48:01.756',
        slug: 'lola-cosmetics',
        description:
          'A empresa, que adota processos 100% veganos e aposta em uma comunicação visual inovadora, foi a pioneira na introdução de produtos No e Low Poo para cachos no Brasil.',
      },
      {
        id: 3,
        image: 'novah-alimentos.png',
        name: 'Novah Alimentos',
        address: 'Rua São Miguel, 224 – Florianópolis – SC – 88030-320',
        contact_information: '(48) 9 9950-5068',
        website: 'https://novahnatural.com.br/',
        make_test_in_animals: 0,
        sponsor_animal_cruelty: 0,
        created_at: '2022-09-05 18:48:01.786',
        updated_at: '2022-09-05 18:48:01.786',
        slug: 'novah-alimentos',
        description:
          'A NOVAH surgiu para ajudar a promover a transformação na maneira como nos alimentamos para assim alcançarmos o nosso máximo potencial sem causar impactos negativos a nossa saúde, aos animais e ao planeta, ou seja, da maneira mais simples, natural, consciente e sustentável.',
      },
      {
        id: 4,
        image: 'dolly.png',
        name: 'Dolly Refrigerantes',
        address:
          'Av. Paranapanema, 192 - Parque Reid, Diadema - SP, 09930-450, Brazil',
        contact_information: '(55) 21 3298-8000',
        website: 'https://dolly.com.br',
        make_test_in_animals: 1,
        sponsor_animal_cruelty: 1,
        created_at: '2022-09-10 18:48:01.786',
        updated_at: '2022-09-10 18:48:01.786',
        slug: 'dolly-refrigerantes',
        description:
          'Uma marca 100% nacional no mercado de refrigerantes desde 1987, a Dolly destaca-se pela qualidade de seus produtos e, principalmente, por ser a pioneira no mercado de refrigerantes dietéticos no Brasil. A preocupação com esta qualidade começa na escolha da matéria-prima adquirida pelos melhores fornecedores do mercado. Com uma equipe de profissionais de alto nível e ampla experiência no mercado, os refrigerantes Dolly são amplamente comercializados. Toda a produção conta com tecnologia de ponta, tanto na área industrial como no controle de qualidade, atendendo aos rigorosos padrões do Ministério da Agricultura e da Saúde.',
      },
    ];
  }
}
