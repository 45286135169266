import API from 'api';

import BookForm from 'pages/books/book.form';
import BooksPage from 'pages/books/books.page';

const BooksController = {
  basePath: '/livros',
  form: BookForm,
  page: BooksPage,
  resourceAPI: API.Book,
};

export default BooksController;
