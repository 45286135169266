import React from 'react';
import { useQuery } from 'react-query';
import { useParams, useNavigate } from 'react-router-dom';

import Loading from 'components/Loading/Loading';
import { alert } from 'lib/notifications';

import './styles.scss';

const CrudForm = ({ formFor, form, resourceAPI, resourceLoader }) => {
  const { id } = useParams();
  const navigate = useNavigate();

  const fetchResource = async () => {
    let result = null;

    if (resourceLoader && typeof resourceLoader === 'function') {
      result = await resourceLoader(id);
    } else {
      result = await resourceAPI.find(id);
    }

    return result.data;
  };

  const onSaveHandler = (item) => {
    if (item.id) {
      return onUpdateHandler(item);
    }

    return onCreateHandler(item);
  };

  const onCreateHandler = (item) => {
    resourceAPI.create(item).then(() => {
      alert({ text: 'Criado com sucesso!', icon: 'success' }, () => {
        refetch();
      });
    });
  };

  const onUpdateHandler = (item) => {
    resourceAPI.update(item.id, item).then(() => {
      alert({ text: 'Salvo com sucesso!', icon: 'success' }, () => {
        refetch();
      });
    });
  };

  const { data, isLoading, isRefetching, refetch } = useQuery(
    [`crud-page-form-${formFor}-${id}`],
    fetchResource,
    {
      enabled: id !== null && id !== undefined,
    },
  );

  if (isLoading || isRefetching) {
    return <Loading />;
  }

  const ResourceForm = form;

  return (
    <div className="crud crud-form">
      <ResourceForm
        onSave={onSaveHandler}
        onClose={() => navigate(-1)}
        resource={data}
      />
    </div>
  );
};

export default CrudForm;
