import React from 'react';
import Form from 'react-bootstrap/Form';

/* Adding form controls to the Form component is a very repetitive task. It
usually involves adding a label with text, followed by an input (or a text area)
with the same attributes repeatedly.

Here is an example:

```
    <Form.Label>Título</Form.Label>
      <Form.Control
        type="text"
        placeholder="Título"
        {...register('title', { required: true })}
      />
      <br />

      <Form.Label>Posição na home</Form.Label>
      <Form.Control
        type="number"
        placeholder="1"
        {...register('home_position')}
      />
      <br />

      <Form.Label>ASINS</Form.Label>
      <Form.Control
        as="textarea"
        placeholder="Asins"
        {...register('asins', {
          onBlur: (e) => setAsins(e.currentTarget.value),
        })}
      />
  ```

  Not only that, but every time a control is required, we need to add an asterix
  to the label, and the `required` property to the input, and make sure it has
  the `required` class when the form is submitted without any value on the
  input. Finally, we want to add a message below the input/textarea to inform
  users that the field is required.

  Instead of copying and pasting the same code everywhere, we can use the
  `FormControl` component. It receives a set of attributes that we would add to
  the input/textarea anyway, but the common functionality, like the validation
  class and message, are hidden, so we don't have to worry about it anymore.

  Using this component, the example above would look like this:

  ```
    <FormControl
      attribute="title"
      label="Título"
      required
      errors={errors}
      register={register}
    />

    <FormControl
      attribute="home_position"
      label="Posição na home"
      placeholder="1"
      errors={errors}
      register={register}
    />

    <FormControl
      attribute="asins"
      label="Asins"
      onBlur={(e) => setAsins(e.currentTarget.value)}
      errors={errors}
      register={register}
    />
  ```

  A couple of things to note:

  * There is no more need for `<br />` between each label/control.
  * When the type of the control is `text` (rendering an input), the `type`
    attribute can be omitted. If you need a textarea, explicitly use
    `type="textarea"`.
  * `errors` and `register` are required and used in all controls we are going
    to create. Even though this is a repetitive task, they are either created on
    the form or in the `CrudForm` component, so there is no way to make this
    work except by passing them down to the `FormControl` component.
  * When a placeholder is not set, the `FormControl` will use the label.
*/
const FormControl = ({
  attribute,
  errors,
  label,
  onBlur,
  onChange,
  placeholder,
  register,
  required,
  type,
}) => (
  <div className="mt-2 mb-3">
    <Form.Label>
      {label}
      {required && '*'}
    </Form.Label>
    {(!type || type === 'text') && (
      <Form.Control
        className={errors[attribute] && 'required'}
        type={type}
        placeholder={placeholder || label}
        {...register(attribute, { required, onChange, onBlur })}
      />
    )}

    {type === 'textarea' && (
      <Form.Control
        as="textarea"
        className={errors[attribute] && 'required'}
        placeholder={placeholder || label}
        {...register('description', { required })}
      />
    )}

    {errors[attribute]?.type === 'required' && (
      <p className="validation-alert" role="alert">
        {label.toLowerCase()} é um campo obrigatório
      </p>
    )}
  </div>
);

export default FormControl;
