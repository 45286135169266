import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import API from 'api';
import { authActions } from 'store/slices/authSlice';

import './styles.scss';

const Login = () => {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [loading, setLoading] = useState(false);

  const currentUser = useSelector((state) => state.auth.user);
  const dispatch = useDispatch();

  useEffect(() => {
    const emailInput = document.querySelector('[data-email-input]');
    if (emailInput) {
      emailInput.focus();
    }
  }, []);

  if (currentUser) {
    window.location.href = '/';
    return null;
  }

  const validForm = () => email.length > 0 && password.length > 0;

  const handleSubmit = (event) => {
    event.preventDefault();

    if (validForm()) {
      setLoading(true);

      API.Session.login(email, password)
        .then((response) => {
          dispatch(
            authActions.login({
              token: response.headers.authorization.replace('Bearer ', ''),
              user: response.data,
            }),
          );

          window.location.reload();
        })
        .catch((e) => {
          onLoginErrorHandler(e.response);
        });
    } else {
      showError('O email e a senha são campos obrigatórios');
    }
  };

  const onLoginErrorHandler = (response) => {
    setLoading(false);

    if (response.status === 401) {
      showError('Email ou senha incorretos');
    } else {
      showError('Não foi possível fazer login');
    }
  };

  const showError = (message) => {
    const container = document.querySelector('.alert');
    if (container) {
      container.textContent = message;
      container.classList.remove('d-none');
    }
  };

  return (
    <div className="row justify-content-md-center mt-3 vh-100 mt-5">
      <div className="col col-md-4"></div>
      <div className="col-md-4">
        <div className="card">
          <div className="card-head">
            <img
              className="logo"
              src="https://vdeveganca.com.br/assets/home/logo-80e90fea624dc3c1f3df90c73514765106461ee954a306dbb0f08c792700bb61.png"
              alt="Logo do V de Vegança"
              loading="lazy"
            />
          </div>
          <div className="card-body">
            <h2 className="text-center mb-4">Entrar</h2>

            <div className="alert alert-danger border-radius-5 d-none"></div>

            <section className="form-group" id="email">
              <span>Email</span>
              <input
                className="form-control"
                type="email"
                required
                data-email-input
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                onKeyDown={(e) => {
                  if (e.key === 'Enter') {
                    document.querySelector('#password input').focus();
                  }
                }}
              />
            </section>

            <section className="form-group" id="password">
              <span>Senha</span>
              <input
                className="form-control"
                type="password"
                required
                value={password}
                onChange={(e) => setPassword(e.target.value)}
                onKeyDown={(e) => {
                  if (e.key === 'Enter') {
                    handleSubmit();
                  }
                }}
              />
            </section>

            <p>&nbsp;</p>

            <button
              disabled={loading}
              className="w-100 btn btn-primary"
              type="button"
              onClick={handleSubmit}
            >
              Entrar
            </button>
          </div>
        </div>
      </div>
      <div className="col col-md-4"></div>
    </div>
  );
};

export default Login;
