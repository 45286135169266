import React from 'react';
import ReactPaginate from 'react-paginate';

import './pagination.scss';

const Pagination = ({ onPageChange, pageCount, currentPage }) => (
  <div className="pagination-component">
    <ReactPaginate
      breakLabel="..."
      nextLabel=">"
      onPageChange={onPageChange}
      pageRangeDisplayed={5}
      pageCount={pageCount}
      previousLabel="<"
      renderOnZeroPageCount={null}
      pageClassName="page-item"
      pageLinkClassName="page-link"
      previousClassName="page-item"
      previousLinkClassName="page-link"
      nextClassName="page-item"
      nextLinkClassName="page-link"
      breakClassName="page-item"
      breakLinkClassName="page-link"
      marginPagesDisplayed={2}
      containerClassName="pagination"
      activeClassName="active"
      forcePage={currentPage}
    />
  </div>
);

export default Pagination;
