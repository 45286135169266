import React, { useEffect, useState, useRef } from 'react';
import Button from 'react-bootstrap/Button';
import Col from 'react-bootstrap/Col';
import Form from 'react-bootstrap/Form';
import Row from 'react-bootstrap/Row';
import { useForm } from 'react-hook-form';

import FormControl from 'components/FormControl/FormControl';

const getYoutubeId = (url) => url?.match(/(?<=v=)[^&#]+/);

const VideoForm = ({ onSave, onClose, resource }) => {
  const [youtubeId, setYoutubeId] = useState(getYoutubeId(resource?.url));
  const [thumbnail, setThumbnail] = useState();

  const thumbnailRef = useRef(null);

  useEffect(() => {
    const animateClass = 'fade-in-fwd';
    thumbnailRef.current?.classList?.add(animateClass);
    setThumbnail(`https://img.youtube.com/vi/${youtubeId}/0.jpg`);

    const onAnimationEnd = () => {
      thumbnailRef?.current?.classList.remove(animateClass);
    };
    thumbnailRef?.current?.addEventListener('animationend', onAnimationEnd);

    return () => {
      thumbnailRef?.current?.removeEventListener(
        'animationend',
        onAnimationEnd,
      );
    };
  }, [youtubeId]);

  const {
    register,
    formState: { errors },
    handleSubmit,
  } = useForm({
    defaultValues: resource || {},
  });

  const onSubmit = (data) => {
    onSave(data);
  };

  return (
    <Form className="video-form" onSubmit={handleSubmit(onSubmit)}>
      <Form.Group className="mb-3">
        <Row>
          <Col>
            <Row>
              <FormControl
                attribute="title"
                label="Título"
                required
                errors={errors}
                register={register}
              />
            </Row>

            <Row>
              <FormControl
                attribute="description"
                label="Descrição"
                errors={errors}
                register={register}
                type="textarea"
              />
            </Row>

            <Row>
              <FormControl
                attribute="url"
                label="URL"
                errors={errors}
                onBlur={(e) => {
                  const videoId = e.currentTarget.value.match(/(?<=v=)[^&#]+/);
                  setYoutubeId(videoId);
                }}
                register={register}
                required
                placeholder="https://www.youtube.com/watch?v=hrwG1BHdHIk"
              />
            </Row>
          </Col>
          <Col className="mt-1">
            {youtubeId && thumbnail && (
              <img
                alt={resource.title}
                className="thumbnail"
                id="video-thumbnail"
                ref={thumbnailRef}
                src={thumbnail}
              />
            )}
          </Col>
        </Row>
        <Row>
          <Col>
            <section className="form-actions">
              <Button variant="secondary" onClick={onClose}>
                Voltar
              </Button>

              <Button variant="primary" type="submit">
                Salvar
              </Button>
            </section>
          </Col>
        </Row>
      </Form.Group>
    </Form>
  );
};

export default VideoForm;
