/* eslint-disable no-param-reassign */
import { createSlice } from '@reduxjs/toolkit';

const searchSlice = createSlice({
  name: 'search',
  initialState: { opened: false },
  reducers: {
    open(state) {
      state.opened = true;
    },
    close(state) {
      state.opened = false;
    },
    toggle(state) {
      state.opened = !state.opened;
    },
  },
});

export const searchActions = searchSlice.actions;

export default searchSlice;
