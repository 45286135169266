import Resource from './resource';
import Article from './resources/article';
import Company from './resources/company';
import Event from './resources/event';
import Search from './resources/search';
import Session from './resources/session';

const API = {
  Article: new Article('article', '/articles'),
  Book: new Resource('book', '/books'),
  Company: new Company('company', '/companies'),
  Event: new Event('event', '/events'),
  Session: new Session(),
  User: new Resource('user', '/users'),
  Video: new Resource('video', '/videos'),
  Search: new Search(),
};

export default API;
