/* eslint-disable no-param-reassign */
import { createSlice } from '@reduxjs/toolkit';
import Cookie from 'lib/cookie';
import Storage from 'lib/storage';

const userKey = 'user';
const tokenKey = 'token';

const authSlice = createSlice({
  name: 'user',
  initialState: {
    user: Storage.get(userKey),
    token: Storage.get(tokenKey),
  },
  reducers: {
    login(state, action) {
      const { token, user } = action.payload;

      state.user = user;
      state.token = token;

      Storage.set(userKey, user);
      Cookie.set(tokenKey, token, 14);
    },
    logout(state) {
      state.user = null;
      state.token = null;

      Storage.remove(userKey);
      Cookie.remove(tokenKey);
    },
  },
});

export const authActions = authSlice.actions;

export default authSlice;
