import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';

export const alert = (options = {}) => {
  Swal.fire({
    text: options.text,
    icon: options.icon || 'warning',
  }).then(() => {
    if (options.callback && typeof options.callback === 'function') {
      options.callback();
    }
  });
};

export const confirm = (title, text, onConfirm) => {
  const MySwal = withReactContent(Swal);

  MySwal.fire({
    title,
    text,
    icon: 'question',
    showConfirmButton: true,
    showCancelButton: true,
  }).then((response) => {
    if (response.isConfirmed) {
      onConfirm();
    }
  });
};

export const confirmWithMessage = async (title, text, onConfirm) => {
  const MySwal = withReactContent(Swal);

  const { value } = await MySwal.fire({
    input: 'textarea',
    title,
    html: text,
    icon: 'warning',
    showConfirmButton: true,
    showCancelButton: true,
  });

  if (value && value.trim().length > 0) {
    onConfirm(value);
  }
};

/*
  Example:

  confirmWithCustomHtml(
    title: 'Multiple inputs',
    text: 'This work with custom HTML',
    onConfirm: (values) => {
      ...
    },
    html:
      '<textarea id="swal-message"></textarea>' +
      '<input id="swal-input1" class="swal2-input">' +
      '<input id="swal-input2" class="swal2-input">',
    preConfirm: () => {
      return {
        message: document.getElementById('swal-message').value,
        swalInput1: document.getElementById('swal-input1').value,
        swalInput2: document.getElementById('swal-input2').value,
      }
    }
  )
*/
export const confirmWithCustomHtml = async (options) => {
  const MySwal = withReactContent(Swal);

  const result = await MySwal.fire({
    html: options.html,
    title: options.title,
    icon: 'warning',
    showConfirmButton: true,
    showCancelButton: true,
    preConfirm: options.preConfirm,
  });

  if (result.isConfirmed && result.value) {
    options.onConfirm(result.value);
  }
};
