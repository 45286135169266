import React from 'react';
import CrudList from 'components/Crud/CrudList';

import API from 'api';

const BooksPage = () => (
  <CrudList
    title="Livros"
    columns={{
      title: { text: 'Título' },
      home_position: { text: 'Posição na home' },
    }}
    resourceAPI={API.Book}
  />
);

export default BooksPage;
