import React from 'react';
import { Routes, Route } from 'react-router-dom';

import DashboardPage from './pages/dashboard/dashboard.page';
import LoginPage from './pages/login/login.page';
import PrivateRoute from './components/PrivateRoute/PrivateRoute';
import { BooksController, VideosController } from './config/router/controllers';
import { resources } from './config/router/router';

// import ProductsPage from './pages/products/products.page';
// import IngredientsPage from './pages/ingredients/ingredients.page';
// import CompaniesPage from './pages/companies/companies.page';
// import VideosPage from './pages/videos/videos.page';
// import BooksPage from './pages/books/books.page';
// import ArticlesPage from './pages/articles/articles.page';
// import EventsPage from './pages/events/events.page';

const CustomRoutes = () => (
  <Routes>
    {/* <Route exact path="/" element={<DashboardPage />} />
    <Route exact path="/produto" element={<ProductsPage />} />
    <Route exact path="/ingrediente" element={<IngredientsPage />} />
    <Route exact path="/empresa" element={<CompaniesPage />} />
    <Route exact path="/videos" element={<VideosPage />} />
    <Route exact path="/livro" element={<BooksPage />} />
    <Route exact path="/artigo" element={<ArticlesPage />} />
    <Route exact path="/evento" element={<EventsPage />} />
    <Route exact path="/" element={<LoginPage />} /> */}

    {/* Public routes */}
    <Route exact path="/login" element={<LoginPage />} />

    {/* Private routes */}
    <Route
      exact
      path="/"
      element={
        <PrivateRoute>
          <DashboardPage />
        </PrivateRoute>
      }
    />

    {resources('book', BooksController)}
    {resources('video', VideosController)}
  </Routes>
);

export default CustomRoutes;
