import Resource from './resource';

export default class MockedResource extends Resource {
  all() {
    return new Promise((resolve) => {
      setTimeout(
        resolve({
          data: this.list,
          pagination: {},
        }),
        Math.random() * 1000,
      );
    });
  }

  find(id) {
    return new Promise((resolve) => {
      setTimeout(
        resolve({
          data: this.list.find((item) => item.id.toString() === id.toString()),
        }),
        Math.random() * 1000,
      );
    });
  }

  create(data) {
    const newList = [...this.list];
    newList.push({ ...data });
    this.list = newList;

    return this.returnList();
  }

  update(data) {
    const resourceIndex = this.list.findIndex((item) => item.id === data.id);
    const newList = [...this.list];
    newList[resourceIndex] = data;
    this.list = newList;
    return this.returnList();
  }

  destroy(id) {
    this.list = this.list.filter((item) => item.id !== id);
    return this.returnList();
  }

  returnList() {
    return new Promise((resolve) => {
      setTimeout(
        resolve({
          data: this.list,
        }),
        Math.random() * 1000,
      );
    });
  }
}
