import client from 'api/client';

export default class Resource {
  constructor() {
    this.client = client;
  }

  search(params = {}) {
    return this.client.get('v1/search', {
      params: {
        q: params.q,
        resource: params.resource,
        page: params.page || 1,
        per_page: params.per_page || 50,
      },
    });
  }
}
