import axios from 'axios';

import Cookie from 'lib/cookie';
import Storage from 'lib/storage';

const client = axios.create({
  baseURL: `${process.env.REACT_APP_BACKEND_API_URL}/api/v1`,
  headers: {
    'Content-Type': 'application/json',
  },
});

// This client does not have a middleware that checks for the token.
// It is important when logging a user in (or in any public route), or we will request
//  a token in the request that is creating the token.
export const publicClient = axios.create({
  baseURL: `${process.env.REACT_APP_BACKEND_API_URL}/api/v1`,
  headers: {
    'Content-Type': 'application/json',
  },
});

client.interceptors.request.use(
  (config) => {
    // TODO: Is there a way to get the `token` key from a central place?
    // it exists on authSlice.js, on ButtonsArea.js and here.
    const token = Cookie.get('token');

    if (token) {
      // eslint-disable-next-line no-param-reassign
      config.headers.Authorization = `Bearer ${token}`;
    } else {
      Storage.remove('user');

      window.location = '/login';
    }

    return config;
  },
  (error) => Promise.reject(error),
);

export default client;
