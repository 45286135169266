import React, { useState, useEffect } from 'react';
import AnimateHeight from 'react-animate-height';
import Button from 'react-bootstrap/Button';
import Col from 'react-bootstrap/Col';
import Form from 'react-bootstrap/Form';
import InputGroup from 'react-bootstrap/InputGroup';
import Row from 'react-bootstrap/Row';
import { useSelector } from 'react-redux';

import API from 'api';

import loading from './loading.gif';
import SearchResults from './SearchResults';

import './styles.scss';

const SearchPanel = () => {
  const opened = useSelector((state) => state.search.opened);

  const [currentPage, setCurrentPage] = useState(1);

  const [searching, setSearching] = useState(false);
  const [searchPagination, setSearchPagination] = useState({});
  const [searchResults, setSearchResults] = useState();
  const [searchTerm, setSearchTerm] = useState('');

  const [includeProducts, setIncludeProducts] = useState(true);
  const [includeIngredients, setIncludeIngredients] = useState(true);
  const [includeCompanies, setIncludeCompanies] = useState(true);
  const [includeArticles, setIncludeArticles] = useState(true);
  const [includeVideos, setIncludeVideos] = useState(true);

  useEffect(() => {
    // TODO: how to prevent this search from running on the first page load?
    // onSearch();
  }, [currentPage]);

  const buildResourcesList = () => {
    const resources = [];

    if (includeProducts) {
      resources.push('product');
    }

    if (includeIngredients) {
      resources.push('ingredient');
    }

    if (includeCompanies) {
      resources.push('company');
    }

    if (includeArticles) {
      resources.push('article');
    }

    if (includeVideos) {
      resources.push('video');
    }

    return resources.join(',');
  };

  const onPageChange = (event) => {
    const nextPage = parseInt(event.selected, 10) + 1;
    setCurrentPage(nextPage);
  };

  const onSearch = () => {
    setCurrentPage(1);
    setSearching(true);

    API.Search.search({
      q: searchTerm,
      resource: buildResourcesList(),
      per_page: 10,
      page: currentPage,
    }).then((response) => {
      const body = response.data;

      setSearching(false);
      setSearchResults(body.data.resources);
      setSearchPagination(body.data.pagination);
    });
  };

  return (
    <AnimateHeight
      id="search-panel"
      duration={500}
      height={opened ? 400 : 0}
      style={{ overflow: 'overlay' }}
    >
      <Row>
        <Col xs={11}>
          <Form.Group className="mb-3">
            <InputGroup>
              <InputGroup.Text>
                <i className="fa fa-magnifying-glass"></i>
              </InputGroup.Text>
              <Form.Control
                aria-label="Search"
                id="search-bar"
                placeholder="Pesquise produtos, ingredientes, empresas, artigos e vídeos"
                type="text"
                defaultValue={searchTerm}
                onBlur={(e) => setSearchTerm(e.currentTarget.value)}
              />
              <Button variant="outline-dark" onClick={onSearch}>
                Pesquisar
              </Button>
            </InputGroup>
          </Form.Group>
        </Col>
      </Row>
      <Row>
        <Col>
          <div className="filters">
            <Form.Check
              type="checkbox"
              label="Produtos"
              defaultChecked={includeProducts}
              onClick={() => setIncludeProducts(!includeProducts)}
            />
            <Form.Check
              type="checkbox"
              label="Ingredientes"
              defaultChecked={includeIngredients}
              onClick={() => setIncludeIngredients(!includeIngredients)}
            />
            <Form.Check
              type="checkbox"
              label="Empresas"
              defaultChecked={includeCompanies}
              onClick={() => setIncludeCompanies(!includeCompanies)}
            />
            <Form.Check
              type="checkbox"
              label="Artigos"
              defaultChecked={includeArticles}
              onClick={() => setIncludeArticles(!includeArticles)}
            />
            <Form.Check
              type="checkbox"
              label="Vídeos"
              defaultChecked={includeVideos}
              onClick={() => setIncludeVideos(!includeVideos)}
            />
          </div>
        </Col>
      </Row>

      {searching && (
        <Row>
          <img className="loading" src={loading} alt="loading" />
        </Row>
      )}

      {!searching && searchResults && searchResults.length > 0 && (
        <Row className="results">
          <Col xs={11}>
            <SearchResults
              items={searchResults}
              pageCount={searchPagination.total_pages || 0}
              onPageChange={onPageChange}
              currentPage={currentPage}
            />
          </Col>
        </Row>
      )}
    </AnimateHeight>
  );
};

export default SearchPanel;
