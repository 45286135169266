const menuItems = [
  {
    name: 'Dashboard',
    icon: 'tachometer-alt',
    path: '/',
  },
  {
    name: 'Alimentos',
    icon: 'carrot',
    path: '/alimentos',
  },
  {
    name: 'Artigos',
    icon: 'newspaper',
    path: '/artigos',
  },
  {
    name: 'Vídeos',
    icon: 'play',
    path: '/videos',
  },
  {
    name: 'Eventos',
    icon: 'calendar-alt',
    path: '/eventos',
  },
  {
    name: 'Livros',
    icon: 'book-open',
    path: '/livros',
  },
];

export default menuItems;
