import React from 'react';
import { useNavigate } from 'react-router-dom';

import MenuItem from 'components/Navigation/MenuItem';
import menuItems from 'components/Navigation/menu';
import { getPathDomainFromUrl } from 'lib/url';

import './styles.scss';

const Navigation = () => {
  const navigate = useNavigate();

  const changePage = (item) => {
    navigate(item.path);
  };

  const currentPath = getPathDomainFromUrl();

  const icons = () =>
    menuItems.map((item) => (
      <MenuItem
        key={item.name}
        menuKey={`menu-item-${item.name}`}
        onClick={() => {
          changePage(item);
        }}
        icon={item.icon}
        name={item.name}
        active={currentPath === item.path}
      />
    ));

  return <nav id="navigation">{icons()}</nav>;
};

// Wrapping the main class with this functional component
function NavigationWrapper(props) {
  const navigate = useNavigate();
  return <Navigation {...props} navigate={navigate} />;
}

export default NavigationWrapper;
