import React from 'react';

import Breadcrumb from './Breadcrumb';
import UserDisplay from './UserDisplay';
import ButtonsArea from './ButtonsArea';

import './styles.scss';

const TopBar = () => (
  <header className="top-bar">
    <UserDisplay />
    <Breadcrumb />
    <ButtonsArea />
  </header>
);

export default TopBar;
