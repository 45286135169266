import React from 'react';

const menuItem = ({ menuKey, name, icon, active, onClick }) => (
  <div
    key={menuKey}
    className={`menu-item ${active ? 'active' : ''}`}
    onClick={onClick}
  >
    <i className={`fa fa-${icon}`}></i>
    <span className="title">{name}</span>
  </div>
);

export default menuItem;
