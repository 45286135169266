import React from 'react';
import { useSelector } from 'react-redux';

const UserDisplay = () => {
  const currentUser = useSelector((state) => state.auth.user);

  return (
    <div className="userDisplay">
      <i className="fa fa-user-circle"></i>
      {currentUser && currentUser.name}
      <span>&nbsp;/</span>
    </div>
  );
};

export default UserDisplay;
