import React from 'react';
import { BrowserRouter } from 'react-router-dom';
import { QueryClient, QueryClientProvider } from 'react-query';
import { useSelector } from 'react-redux';

import CustomRoutes from 'routes';
import Login from 'components/Login/Login';
import Navigation from 'components/Navigation/Navigation';
import SearchPanel from 'components/SearchPanel/SearchPanel';
import TopBar from 'components/TopBar/TopBar';

import 'bootstrap/dist/css/bootstrap.min.css';
import 'App.scss';
import 'animation.scss';

function App() {
  const currentUser = useSelector((state) => state.auth.user);

  const queryClient = new QueryClient({
    defaultOptions: {
      queries: {
        // if true, it will refetch data when the browser's tab gains focus.
        // It does not work well on admin because of nested forms.
        refetchOnWindowFocus: false,
      },
    },
  });

  return (
    <BrowserRouter>
      <QueryClientProvider client={queryClient}>
        {currentUser && (
          <>
            <Navigation />

            <div>
              <TopBar />
              <SearchPanel />

              <div className="workspace">
                <CustomRoutes />
              </div>
            </div>
          </>
        )}

        {!currentUser && <Login />}
      </QueryClientProvider>
    </BrowserRouter>
  );
}

export default App;
