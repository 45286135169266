export default class Storage {
  static get(key, options = {}) {
    const storedValue = localStorage.getItem(key);
    let result = null;

    try {
      result = JSON.parse(storedValue);
    } catch (_err) {
      result = storedValue;
    }

    return Storage.returnValueFor(result || options.defaultValue);
  }

  static set(key, value) {
    localStorage.setItem(
      key,
      typeof value === 'object' ? JSON.stringify(value) : value,
    );
  }

  // It will iterate over an array of keys and add them to the localStorage.
  // Each key will be added with the value of the key in the array.
  //
  // Example:
  //
  // Storage.setMultiple({ 'user': { name: 'John' }, 'token': 'john-token' });
  static setMultiple(keyValues) {
    Object.keys(keyValues).forEach((key) => {
      Storage.set(key, keyValues[key]);
    });
  }

  static remove(key) {
    localStorage.removeItem(key);
  }

  // It will iterate over an array of keys and remove them from the localStorage.
  static removeMultiple(keys) {
    keys.forEach((key) => {
      Storage.remove(key);
    });
  }

  // It will return the value of the key, making sure it is a valid value.
  // A valid value is anything with a value or null (it will return null if
  // the value is undefined).
  static returnValueFor(value) {
    if (value === undefined) {
      return null;
    }

    return value;
  }
}
