import React, { useState } from 'react';

import API from 'api';

import CrudList from 'components/Crud/CrudList';
import Filters from 'components/Filter/Filter';

import './styles.scss';

const VideosPage = () => {
  const [filters, setFilters] = useState({});

  const filterFields = [
    {
      attribute: 'title',
      type: 'text',
      label: 'Título do vídeo',
      id: 'video-title-filter',
      klass: '',
    },
  ];

  const sortingFields = {
    title: { direction: 'asc' },
  };

  return (
    <div className="videos-page">
      <Filters fields={filterFields} applyFilters={setFilters} />
      <hr />

      <CrudList
        title="Vídeos"
        columns={{
          thumbnail: {
            text: 'Imagem',
            render: (video) => {
              if (!video.thumbnail) {
                return <div></div>;
              }

              return (
                <a href={video.url} target="_blank">
                  <img
                    className="thumbnail"
                    alt={video.title}
                    src={video.thumbnail}
                  />
                </a>
              );
            },
          },
          title: { text: 'Título' },
        }}
        filters={filters}
        resourceAPI={API.Video}
        sortingFields={sortingFields}
      />
    </div>
  );
};

export default VideosPage;
