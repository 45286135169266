import React, { useState } from 'react';
import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import { useForm } from 'react-hook-form';

const BookForm = ({ onSave, onClose, resource }) => {
  const [asins, setAsins] = useState(resource ? resource.data.asins : null);
  const [linkId, setLinkId] = useState(resource ? resource.data.link_id : null);

  const { register, handleSubmit } = useForm({
    defaultValues: resource || {},
    mode: 'all',
  });

  const onSubmit = (data) => {
    onSave(data);
  };

  return (
    <Form onSubmit={handleSubmit(onSubmit)}>
      <Row>
        <Col xs={8}>
          <Form.Group className="mb-3">
            <Form.Label>Título</Form.Label>
            <Form.Control
              type="text"
              placeholder="Título"
              {...register('title', { required: true })}
            />
            <br />

            <Form.Label>Posição na home</Form.Label>
            <Form.Control
              type="number"
              placeholder="1"
              {...register('home_position')}
            />
            <br />

            <Form.Label>ASINS</Form.Label>
            <Form.Control
              as="textarea"
              placeholder="Asins"
              {...register('data.asins', {
                onBlur: (e) => setAsins(e.currentTarget.value),
              })}
            />

            <br />

            <Form.Label>LINK ID</Form.Label>
            <Form.Control
              as="textarea"
              placeholder="Link id"
              {...register('data.link_id', {
                onBlur: (e) => setLinkId(e.currentTarget.value),
              })}
            />

            <br />

            <section className="form-actions">
              <Button variant="secondary" onClick={onClose}>
                Voltar
              </Button>
              <Button variant="primary" type="submit">
                Salvar
              </Button>
            </section>
          </Form.Group>
        </Col>
        <Col xs={2}>
          {asins != null && (
            <iframe
              height="100%"
              title={asins}
              src={`https://ws-na.amazon-adsystem.com/widgets/q?ServiceVersion=20070822&OneJS=1&Operation=GetAdHtml&MarketPlace=BR&source=ac&ref=qf_sp_asin_til&ad_type=product_link&tracking_id=vdeveganca-20&marketplace=amazon&region=BR&placement=${asins}&asins=${asins}&linkId=${linkId}&show_border=false&link_opens_in_new_window=false&price_color=333333&title_color=1c731a&bg_color=ffffff`}
            />
          )}
        </Col>
      </Row>
    </Form>
  );
};

export default BookForm;
