/* eslint-disable react/no-array-index-key */
import React, { useState } from 'react';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';

import './styles.scss';

/*
  Filters is a component that builds filters for a list page.

  Attributes:

  * fields: a list of fields that will be converted to filter elements.
    Example:

    [
      {
        attribute: 'number', // attribute from the model
        type: 'text',
        label: 'Número do pedido', // text that will be displayed
        id: 'order-number-filter', // HTML id of the field
        klass: 'order-number-filter-container', // extra class you can attach to the element
      },
      {
        attribute: 'status',
        type: 'select',
        label: 'Status',
        id: 'status-filter',
        klass: 'status-filter-container',
        options: [
          {text: 'Pré agendado', value: 'pre_booking'},
          {text: 'Requisitado', value: 'placed'},
          ...
        ],
      }
    ]
*/
const Filters = ({ fields, applyFilters }) => {
  const [filters, setFilters] = useState({});

  const updateFilter = (attribute, value) => {
    const newFilters = { ...filters };
    newFilters[attribute] = value;
    setFilters(newFilters);
  };

  const removeFilter = (filter) => {
    const newFilters = { ...filters };
    delete newFilters[filter];
    setFilters(newFilters);
  };

  const buildHtmlForField = (field) => {
    switch (field.type) {
      case 'text':
        return inputTypeField(field);
      case 'select':
        return selectTypeField(field);
      default:
        return null;
    }
  };

  const inputTypeField = (field) => (
    <>
      <Form.Label htmlFor={field.id}>{field.label}</Form.Label>
      <Form.Control
        type="text"
        id={field.id}
        aria-describedby={`Filtro: ${field.label}`}
        onChange={(e) => {
          if (e.target.value.trim().length > 0) {
            updateFilter(field.attribute, e.target.value);
          } else {
            removeFilter(field.attribute);
          }
        }}
        onKeyDown={(e) => {
          if (e.key === 'Enter') {
            applyFilters(filters);
          }
        }}
      />
    </>
  );

  const selectTypeField = (field) => (
    <>
      <label htmlFor={field.id}>{field.label}</label>
      <select
        id={field.id}
        className="form-select"
        onChange={(e) => {
          if (e.target.value.trim().length > 0) {
            updateFilter(field.attribute, e.target.value);
          } else {
            removeFilter(field.attribute);
          }
        }}
      >
        {field.options.map((option, index) => (
          <option key={`option-${index}`} value={option.value}>
            {option.text}
          </option>
        ))}
      </select>
    </>
  );

  const colClass = (field) =>
    `${field.klass || ''} ${
      field.type === 'select' ? 'select-filter-container' : ''
    }`;

  return (
    <Row>
      {fields.map((field, index) => (
        <Col key={`filter-field-${index}`} className={colClass(field)}>
          {buildHtmlForField(field)}
        </Col>
      ))}
      <Col className="filter-action">
        <Button
          variant="primary"
          onClick={() => {
            applyFilters(filters);
          }}
        >
          Filtrar
        </Button>
      </Col>
    </Row>
  );
};

export default Filters;
