import React from 'react';
import { Navigate } from 'react-router-dom';
import { useSelector } from 'react-redux';

const PrivateRoute = ({ children }) => {
  const currentUser = useSelector((state) => state.auth.user);

  return currentUser ? (
    children
  ) : (
    <Navigate to={`/login?url=${encodeURI(window.location.pathname)}`} />
  );
};

export default PrivateRoute;
