import React from 'react';
import Pagination from 'components/Pagination/Pagination';

const ResourceTypes = {
  Ingredient: 'Ingrediente',
  Product: 'Produto',
  Company: 'Empresa',
  Article: 'Artigo',
  Video: 'Vídeo',
};

const SearchResults = ({ onPageChange, pageCount, items, currentPage }) => {
  if (!items || items.length === 0) {
    return null;
  }

  return (
    <>
      <table className="table table-hover crud-table">
        <thead>
          <tr>
            <th scope="col">#</th>
            <th scope="col">Nome</th>
            <th scope="col">Tipo</th>
          </tr>
        </thead>
        <tbody>
          {items.map((item) => (
            <tr key={`${item.resource} ${item.id}`}>
              <th scope="row">{item.id}</th>

              <td>{item.name || item.title}</td>
              <td>{ResourceTypes[item.resource]}</td>
            </tr>
          ))}
        </tbody>
      </table>

      {pageCount > 1 && (
        <Pagination
          onPageChange={onPageChange}
          pageCount={pageCount}
          currentPage={currentPage - 1}
        />
      )}
    </>
  );
};

export default SearchResults;
