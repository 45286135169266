/* eslint-disable no-multi-str */
import MockedResource from 'api/mocked-resource';

export default class Event extends MockedResource {
  constructor(rootKey, uri) {
    super(rootKey, uri);

    this.list = [
      {
        id: 1,
        title:
          'Compreendendo a psicologia da opressão para capacitar o movimento vegano',
        subtitle: 'com Melanie Joy',
        description:
          '<p>Aprenda novas habilidades e ferramentas para levar sua defesa vegana para o pr&oacute;ximo n&iacute;vel!</p><p><br />\
            <strong>Sobre este evento</strong></p><p><br />\
            Neste webinar, a psic&oacute;loga Melanie Joy explica a Psicologia que nos leva a acreditar que certos indiv&iacute;duos e grupos s&atilde;o mais dignos de serem tratados com respeito do que outros.\
            Ela explica a mentalidade subjacente &agrave; opress&atilde;o e ao abuso e que pode fazer com que os defensores da compaix&atilde;o e da justi&ccedil;a se envolvam nos mesmos comportamentos que est&atilde;o tentando transformar. Ela tamb&eacute;m fala sobre como usar esse entendimento para ajudar a criar um movimento vegano mais fortalecido.</p>\
            <p>Uma grava&ccedil;&atilde;o do webinar ser&aacute; disponibilizada para quem se inscrever no evento.</p>',
        url: 'https://www.eventbrite.com/e/understanding-the-psychology-of-oppression-to-empower-the-vegan-movement-tickets-106906095008',
        date: '2022-06-06 12:00',
        duration: 60,
        language: 'Inglês',
        image: 'event.jpeg',
        fee: 0,
        status: 'published',
      },
      {
        id: 2,
        title: 'Vegfest 2019',
        description:
          '<p>Um dos maiores eventos das Américas terá sua sétima edição de 10 a 14 de outubro, em São Paulo. Realizado pela Sociedade Vegetariana Brasileira, o Vegfest 2018, anteriormente denominado Congresso Vegetariano Brasileiro, teve sua primeira edição em 2006 e já passou por São Paulo, Belo Horizonte, Porto Alegre, Curitiba e Recife. </p>\
          <p>Agora será realizado no Novotel Center Norte e terá junto uma Feira Vegana e ainda uma ampla área de entretenimento, lazer e gastronomia vegana. </p>',
        url: 'http://vegfest.com.br/',
        date: '2022-05-04',
        duration: 90,
        language: 'Português',
        image: 'event.jpeg',
        fee: 205.5,
        status: 'published',
      },
      {
        id: 3,
        title: 'Terceira Feira Vegan SP',
        description:
          '<p>Uma feira com o objetivo de:</p>\
          <p>- espalhar o veganismo acessível</p><p>- incentivar o consumo consciente e a economia criativa</p>\
          <p>Teremos expositores de comida vegana, cosméticos veganos, produtos sustentáveis, acessórios, itens de decoração, flash tattoo e muito mais!</p>',
        url: 'https://www.facebook.com/events/casa-tamarindo/feira-vegan-sp-3%C2%AA-edi%C3%A7%C3%A3o/505819596828873/',
        date: '2019-08-25',
        duration: 90,
        language: 'Português',
        status: 'published',
      },
      {
        id: 4,
        title: "Encontro Vegano JMA J'adore mes amis 2º Edição MIAU",
        description:
          '<p>Iniciaremos o encontro com a prática de Yóga e Meditação, seguindo com nosso bazar cheio de itens Veganos lindos, com moda e acessórios;\
          <p>artesanato; cosméticos; além da deliciosa gastronomia vegana e feirinha de hortifrúti orgânicos! </p>\
          <p>Também teremos Roda de Bate Papo, Palestras,  Cantinho do Desapego, participação de ONGs, protetores e muito mais!</p>\
          <p>Venha passar um domingo conosco, saborear delícias veganas e conhecer mais sobre Veganismo e um mundo mais justo para todos os animais 😊💚</p>',
        url: 'https://www.facebook.com/events/casa-tamarindo/feira-vegan-sp-3%C2%AA-edi%C3%A7%C3%A3o/505819596828873/',
        date: '2019-08-18',
        duration: 60,
        language: 'Português',
        status: 'published',
      },
    ];
  }
}
