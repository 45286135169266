import React, { useState, useEffect } from 'react';
import Button from 'react-bootstrap/Button';
import { useQuery } from 'react-query';
import { useLocation, useNavigate } from 'react-router-dom';

import Loading from 'components/Loading/Loading';
import Pagination from 'components/Pagination/Pagination';
import { confirm } from 'lib/notifications';
import { buildUri } from 'lib/url';
import { useTableBuilder } from './useTableBuilder';

import './styles.scss';

const CrudList = ({
  columns,
  hideDeleteButton,
  hideId,
  hideNewButton,
  hideTitle,
  resourceAPI,
  resourceKey,
  title,

  /*
    Example of filters:

    [
      {
        attribute: 'number',
        type: 'text',
        label: 'Número do pedido',
        id: 'order-number-filter',
        klass: 'order-number-filter-container',
      },
      {
        attribute: 'status',
        type: 'select',
        label: 'Status',
        id: 'status-filter',
        klass: 'status-filter-container',
        options: [{ text: 'Todos', value: '' }].concat(statusFilterOptions()),
      },
    ];
  */
  filters,

  /*
    Example of sortingFields:

    {
      number: { direction: 'off' },
      status: { direction: 'off' },
      inserted_at: { direction: 'desc' },
    };
  */
  sortingFields,
}) => {
  const [orderBy, setOrderBy] = useState(sortingFields || {});
  const [currentPage, setCurrentPage] = useState(1);
  const [loading, setLoading] = useState(true);

  const location = useLocation();
  const navigate = useNavigate();

  const { renderColumn } = useTableBuilder(columns);

  useEffect(() => {
    refetch();
  }, [currentPage]);

  const buildSortingFields = () => {
    const fields = Object.keys(orderBy)
      .filter((key) => orderBy[key].direction !== 'off')
      .map((key) => `${key}:${orderBy[key].direction}`)
      .join(',');

    if (fields.length > 0) {
      return {
        order_by: fields,
      };
    }

    return {};
  };

  const buildQueryParameters = () => {
    const paginationParameters = { page: currentPage, page_size: 25 };
    const filtersParameters = filters || {};
    const sortingParameters = buildSortingFields();

    return {
      ...paginationParameters,
      ...filtersParameters,
      ...sortingParameters,
    };
  };

  const loadResourceList = async () => {
    setLoading(true);

    const response = await resourceAPI.all(buildQueryParameters());
    setLoading(false);

    return response.data;
  };

  const {
    data: body,
    isLoading,
    isRefetching,
    refetch,
  } = useQuery([`crud-page-${title}`, currentPage, filters, orderBy], () =>
    loadResourceList(),
  );

  if (!body || isLoading || isRefetching) {
    return <Loading />;
  }

  const resourcesList = body.resources;
  const pageCount = body.pagination ? body.pagination.total_pages : 1;

  const handlePageClick = (event) => {
    const nextPage = parseInt(event.selected, 10) + 1;
    setCurrentPage(nextPage);
  };

  const onEditHandler = (item) => {
    const uri = buildUri([
      location.pathname,
      item[resourceKey || 'id'],
      'editar',
    ]);
    navigate(uri);
  };

  const onNewHandler = () => {
    const uri = buildUri([location.pathname, 'novo']);
    navigate(uri);
  };

  const onDeleteHandler = (id) => {
    confirm(
      'Atenção',
      'Você tem certeza que deseja excluir? Esta operação não poderá ser desfeita.',
      () => {
        resourceAPI.destroy(id).then(() => refetch());
      },
    );
  };

  const showNewButton = () => {
    if (hideNewButton) {
      return null;
    }

    return (
      <section className="actions-container">
        <Button variant="primary" type="submit" onClick={onNewHandler}>
          <i className="fa fa-plus"></i> Novo
        </Button>
      </section>
    );
  };

  if (resourcesList.length === 0 && !loading) {
    return (
      <div>
        {!hideTitle && <h1 className="workspace-header">{title}</h1>}
        <div>Não encontramos nenhum resultado</div>
        {showNewButton()}
      </div>
    );
  }

  const sortingArrows = {
    asc: 'sort-amount-down-alt',
    desc: 'sort-amount-up-alt',
    off: 'sort',
  };

  const buildTableHeader = (attribute) => {
    const sortingField = orderBy[attribute];
    const columnText = columns[attribute].text;

    if (sortingField) {
      const nextDirection = {
        asc: 'desc',
        desc: 'off',
        off: 'asc',
      };

      return (
        <th
          key={attribute}
          className="sortable-column-header"
          scope="col"
          onClick={() => {
            const newOrderBy = { ...orderBy };
            Object.keys(newOrderBy).forEach((key) => {
              if (key !== attribute) {
                newOrderBy[key].direction = 'off';
              }
            });
            newOrderBy[attribute] = {
              direction: nextDirection[orderBy[attribute].direction],
            };
            setOrderBy(newOrderBy);
          }}
        >
          <i
            className={`sorting-direction fa fa-${
              sortingArrows[sortingField.direction]
            }`}
          ></i>
          {columnText}
        </th>
      );
    }

    return (
      <th key={attribute} scope="col">
        {columnText}
      </th>
    );
  };

  return (
    <div className="crud crud-list">
      {!hideTitle && <h1 className="workspace-header">{title}</h1>}

      <table className="table crud-table">
        <thead>
          <tr>
            {!hideId && <th scope="col">#</th>}
            {Object.keys(columns).map((attribute) =>
              buildTableHeader(attribute),
            )}
            <th scope="col" className="action-column"></th>
            <th scope="col" className="action-column"></th>
          </tr>
        </thead>
        <tbody>
          {resourcesList.map((r) => (
            <tr key={r.id}>
              {!hideId && <th scope="row">{r.id}</th>}

              {Object.keys(columns).map((attribute) =>
                renderColumn(r, attribute),
              )}

              <td className="action-column">
                {((resourceKey && r[resourceKey]) ||
                  (!resourceKey && r.id)) && (
                  <i
                    className="far fa-edit"
                    onClick={() => onEditHandler(r)}
                  ></i>
                )}
              </td>

              {!hideDeleteButton && (
                <td className="action-column">
                  <i
                    className="far fa-trash-alt"
                    onClick={() => onDeleteHandler(r.id)}
                  ></i>
                </td>
              )}
            </tr>
          ))}
        </tbody>
      </table>

      {pageCount > 1 && (
        <Pagination
          onPageChange={handlePageClick}
          pageCount={pageCount}
          currentPage={currentPage - 1}
        ></Pagination>
      )}

      {showNewButton()}
    </div>
  );
};

export default CrudList;
