import client, { publicClient } from 'api/client';

export default class Session {
  login(email, password) {
    return publicClient.post('/login', {
      user: {
        email,
        password,
      },
    });
  }

  logout() {
    return client.delete('/logout', {}, {});
  }
}
