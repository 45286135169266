import React from 'react';
import { useDispatch } from 'react-redux';

import API from 'api';
import Cookie from 'lib/cookie';
import { authActions } from 'store/slices/authSlice';
import { searchActions } from 'store/slices/searchSlice';

const ButtonsArea = () => {
  const dispatch = useDispatch();

  const logout = () => {
    const token = Cookie.get('token');
    API.Session.logout(token)
      .then(() => {
        dispatch(authActions.logout());
      })
      .catch(() => {
        dispatch(authActions.logout());
        window.location.reload();
      });
  };

  const toggleSearchArea = () => {
    dispatch(searchActions.toggle());
  };

  return (
    <div id="buttons-area">
      <div
        data-testid="toggle-search-area-button"
        className="action-button"
        onClick={toggleSearchArea}
      >
        <i className="fa fa-magnifying-glass"></i>
      </div>
      <div
        data-testid="logout-button"
        className="action-button"
        onClick={logout}
      >
        <i className="fa fa-sign-out-alt"></i>
      </div>
    </div>
  );
};

export default ButtonsArea;
