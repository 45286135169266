import { configureStore } from '@reduxjs/toolkit';

import authSlice from './slices/authSlice';
import searchSlice from './slices/searchSlice';

const store = configureStore({
  reducer: {
    auth: authSlice.reducer,
    search: searchSlice.reducer,
  },
});

export default store;
