import React from 'react';

export const useTableBuilder = (columns) => {
  const renderColumn = (resource, attribute) => {
    const data = columns[attribute];
    const attributes = {};

    attributes.klass = `crud-table-row ${data.klass}`;
    attributes.text =
      data.render && typeof data.render === 'function'
        ? data.render(resource, data)
        : resource[attribute];

    return (
      <td key={attribute} className={attributes.klass}>
        {attributes.text}
      </td>
    );
  };

  return {
    renderColumn,
  };
};
